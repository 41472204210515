import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./RedeemPrizes.css";
import { useRef, useState } from "react";
import { DataService } from "../../services/DataService";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import { postSelectedPrizeData } from "../../services/dataApi";
import { Console } from "console";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";

const RedeemPrizes: React.FC = () => {
  var router = useIonRouter();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollIconRef = useRef<HTMLIonImgElement | null>(null);
  let scrollThroughBtn = false;
  const [showLoader, setShowLoader] = useState(false);
  const [prizeCount, setPrizeCount] = useState(0);
  var tempArr = [
    {
      id: 1,
      text: "Ruler",
      isSelected: false,
      imagePath: "/assets/images/Ruler_Big.png",
    },
  ];
  const [prizeData, setPrizeData] = useState(tempArr);

  var [prizeSelected, setprizeSelected] = useState(prizeData);
  var [enableButton, setEnableButton] = useState(false);
  var [refresh, setRefresh] = useState(false);

  useIonViewWillEnter(() => {
    var tempArr: any = [];
    if (DataService.selectedPrizeTitle === "Section 1") {
      setPrizeCount(4);
      setEnableButton(true);
      tempArr = [
        {
          id: 4,
          text: "Ruler",
          isSelected: true,
          imagePath: "/assets/images/Ruler_Big.png",
        },
        {
          id: 2,
          text: "Planner",
          isSelected: true,
          imagePath: "/assets/images/Calender Magnet.png",
        },
        {
          id: 1,
          text: "Hanging Door Sign",
          isSelected: true,
          imagePath: "/assets/images/Door Sign.svg",
        },
        {
          id: 3,
          text: "Reminder Stickers",
          isSelected: true,
          imagePath: "/assets/images/Sticky.png",
        },
      ];
    } else if (DataService.selectedPrizeTitle === "Section 2") {
      setPrizeCount(2);
      tempArr = [
        {
          id: 5,
          text: "Sun Protection Magnet",
          isSelected: false,
          imagePath: "/assets/images/Sun protection1.svg",
        },
        {
          id: 6,
          text: "Sun Screen (Carabiner Kind)",
          isSelected: false,
          imagePath: "/assets/images/Carbiner.svg",
        },
        {
          id: 7,
          text: "Sun Screen (Mineral)",
          isSelected: false,
          imagePath: "/assets/images/Mineral.svg",
        },
      ];
    } else if (DataService.selectedPrizeTitle === "First Skin Self Check") {
      setPrizeCount(1);
      tempArr = [
        {
          id: 10,
          text: "Ruler",
          isSelected: false,
          imagePath: "/assets/images/Ruler_Big.png",
        },
        {
          id: 9,
          text: "Sun Hat",
          isSelected: false,
          imagePath: "/assets/images/Prize Hat.svg",
        },
        {
          id: 11,
          text: "Sun Screens",
          isSelected: false,
          imagePath: "/assets/images/Prize Sunscreen.svg",
        },
        {
          id: 8,
          text: "Umbrella",
          isSelected: false,
          imagePath: "/assets/images/Prize Umbrella.svg",
        },
      ];
    } else if (DataService.selectedPrizeTitle === "Second Skin Self Check") {
      setPrizeCount(1);
      tempArr = [
        {
          id: 14,
          text: "Ruler",
          isSelected: false,
          imagePath: "/assets/images/Ruler_Big.png",
        },
        {
          id: 12,
          text: "Sun Hat",
          isSelected: false,
          imagePath: "/assets/images/Prize Hat.svg",
        },
        {
          id: 15,
          text: "Sun Screens",
          isSelected: false,
          imagePath: "/assets/images/Prize Sunscreen.svg",
        },
        {
          id: 13,
          text: "Umbrella",
          isSelected: false,
          imagePath: "/assets/images/Prize Umbrella.svg",
        },
      ];
    } else if (DataService.selectedPrizeTitle === "Sun Goal Update") {
      setPrizeCount(1);
      tempArr = [
        {
          id: 18,
          text: "Ruler",
          isSelected: false,
          imagePath: "/assets/images/Ruler_Big.png",
        },
        {
          id: 16,
          text: "Sun Hat",
          isSelected: false,
          imagePath: "/assets/images/Prize Hat.svg",
        },
        {
          id: 19,
          text: "Sun Screens",
          isSelected: false,
          imagePath: "/assets/images/Prize Sunscreen.svg",
        },
        {
          id: 17,
          text: "Umbrella",
          isSelected: false,
          imagePath: "/assets/images/Prize Umbrella.svg",
        },
      ];
    }
    setPrizeData(tempArr);
    setprizeSelected(tempArr);
  });
  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrolling = () => {
    console.log("SCROLLLLLING");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrollingEnd = () => {
    console.log("SCROLLLLLING ENDDDD");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "visible";
    }
  };

  const optionSelected = (i: number) => {
    var tempArr = prizeSelected;
    tempArr[i].isSelected = !tempArr[i].isSelected;
    setprizeSelected(tempArr);
    setRefresh(!refresh);

    var filteredData = prizeSelected.filter(function (filter) {
      return filter.isSelected;
    });
    if (DataService.selectedPrizeTitle === "Section 1") {
      if (filteredData.length === 4) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else if (DataService.selectedPrizeTitle === "Section 2") {
      if (filteredData.length === 2) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else if (DataService.selectedPrizeTitle === "First Skin Self Check") {
      if (filteredData.length === 1) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else if (DataService.selectedPrizeTitle === "Second Skin Self Check") {
      if (filteredData.length === 1) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    } else if (DataService.selectedPrizeTitle === "Sun Goal Update") {
      if (filteredData.length === 1) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    }
  };
  const saveprizesredeemed = () => {
    //DataService.isPrizeRedeemed = true;
    var submissionObjt: any = {};
    submissionObjt["allselectedPrizes"] = [];
    var obj: any = {};
    obj["title"] = DataService.selectedPrizeTitle;
    obj["selectedPrizes"] = [];
    for (var i = 0; i < prizeSelected.length; i++) {
      var localprize = prizeSelected[i];
      if (localprize.isSelected === true) {
        obj["selectedPrizes"].push({
          value: localprize.text,
          id: localprize.id,
        });
      }
    }
    submissionObjt["allselectedPrizes"].push(obj);
    console.log("submission json", submissionObjt);
    postPrizeData(submissionObjt);
  };

  async function postPrizeData(obj: any) {
    try {
      openLoader();
      const responseData = await postSelectedPrizeData(
        obj,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            console.log("posting Prize success--" + respData);
            // for(var i=0;i<DataService.prizes.length;i++){
            //   var localprize=DataService.prizes[i];
            //   if(localprize.text === DataService.selectedPrizeTitle){
            //     localprize.showRedeemedPrizeDetails=true;
            //     DataService.prizes[i]=localprize;
            //     break;
            //   }
            // }
            router.push("/PrizeSelectedWelldone", "forward", "push");
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(" posting prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
        onIonScrollStart={scrolling}
        onIonScrollEnd={scrollingEnd}
      >
        <LoadingScreen text="Submitting Prize Data" isOpen={showLoader} />
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            DataService.isSelectedBagePrizeTab="Prizes Earned" ;  
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        {/* <ProgressFraction
              percent={50}
              fraction="1/15"
              description="Body Parts"
            /> */}
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">
                {DataService.selectedPrizeTitle + " Prizes"}{" "}
              </IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();

                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="rbpmainContent rpMain">
          {/* <div className="htScrollIconContainer">
            <IonImg
              src="assets/icons/FixedScroll.png"
              className="htScrollIcon"
              onClick={scrollToBottom}
              ref={scrollIconRef}
              alt="none"
            ></IonImg>
          </div> */}
          <IonGrid>
            <IonRow className="rpImageCol">
              <IonCol size="auto">
                <IonImg src="/assets/images/Illustrations.png"></IonImg>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" className="rpTextCol">
                <IonText className="rpText">
                  {"You have completed " + DataService.selectedPrizeTitle}{" "}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid className="rpPrizeGrid">
            <IonRow>
              <IonCol>
                {DataService.selectedPrizeTitle === "Core1 Completion" && (
                  <IonText className="rpTextSelection">
                    <strong>Everyone will get {prizeCount} prizes </strong>
                    from the options below.
                  </IonText>
                )}
                {DataService.selectedPrizeTitle != "Core1 Completion" && (
                  <IonText className="rpTextSelection">
                    <strong>Select {prizeCount} prizes </strong>
                    from the options below.
                  </IonText>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              {prizeData.map((option: any, i: number) => {
                return (
                  <>
                    <IonCol className="rpColbg" size="11.5/2">
                      <IonImg src={option.imagePath} className="rpImg">
                        {" "}
                      </IonImg>
                      {!option.isSelected && (
                        <IonImg
                          src="/assets/icons/Check.png"
                          className="rpCheck"
                          onClick={() => {
                            optionSelected(i);
                          }}
                        ></IonImg>
                      )}
                      {option.isSelected && (
                        <IonImg
                          src="/assets/icons/CheckMarkBordered.png"
                          className="rpCheck"
                          onClick={() => {
                            optionSelected(i);
                          }}
                        ></IonImg>
                      )}
                      <IonText>
                        <span className="rpTextChar">{option.text}</span>
                      </IonText>
                    </IonCol>
                    {i % 2 === 0 && <IonCol size="0.5"></IonCol>}
                  </>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              {!enableButton && (
                <IonButton
                  className="loginBtnEnabled scsdNextBtn"
                  disabled={true}
                  onClick={() => {}}
                >
                  <IonImg
                    className="sceContinueImg"
                    src="/assets/icons/CheckCircleOutline.png"
                  />
                  <IonText>Confirm</IonText>
                </IonButton>
              )}
              {enableButton && (
                <IonButton
                  className="loginBtnEnabled scsdNextBtn"
                  onClick={() => {
                    {
                      saveprizesredeemed();
                    }
                  }}
                >
                  <IonImg
                    className="sceContinueImg"
                    src="/assets/icons/CheckCircleOutline.png"
                  />
                  <IonText>Confirm</IonText>
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default RedeemPrizes;
