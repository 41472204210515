import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";


import { useState } from "react";

const CC7: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const navigateBack = () => {
    router.goBack();
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                  className="settingsIcon"
                  icon={settingsOutline}
                  style={{ color: "transparent" }}
                ></IonIcon> */}
            <IonText className="titleText">Page 9</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
        <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Sunscreen
          </IonText>
          
            <div style={{ width: "100%", height: "16px" }} />

          

          {/* Main body here */}
         
          <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">
              The best <strong>sunscreen</strong> is the one <strong>you’ll</strong> use most often.   There are many different types of sunscreen available, so find the one that works best for you.  Consider the following points when picking out a sunscreen:
              <ul style={{ marginLeft: "16px" }}>
                <li>Find a sunscreen with an <strong>SPF</strong> of <strong>30</strong> or higher​​</li>
                <li>Apply a <strong>generous</strong> amount (at least one ounce) to all exposed body parts​​​</li>
                <li><strong>Reapply</strong> at least every <strong>2 hours</strong>​</li>
              </ul>
            </IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "There are two types of sunscreen: <strong>chemical</strong> and <strong>physical</strong>. Chemical sunscreen absorbs into the skin like a sponge while physical sunscreen sits on top of the skin acting as a shield.",
              }}
            ></IonText>
            <div style={{ width: "100%", height: "16px" }} />
        <IonImg src="/assets/images/cc/Sunscreen.svg"></IonImg>
          </div>
          <div style={{ width: "100%", height: "116px" }} />
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc8")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC7;
