import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import { useState } from "react";
import "./CC5.css";

const CC5: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const navigateBack = () => {
    router.goBack();
  };




  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                  className="settingsIcon"
                  icon={settingsOutline}
                  style={{ color: "transparent" }}
                ></IonIcon> */}
            <IonText className="titleText titleZ">Page 7​</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
        <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Cumulative Sun Damage
          </IonText>

          {/* Main body here */}
          <div style={{ width: "100%", height: "16px" }} />
       

         
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "Sun damage is <strong>CUMULATIVE</strong>, meaning it adds up over the course of your life.  Whenever you are <strong>unprotected</strong> in the sun, it damages your skin and adds up for <strong>total skin damage</strong>.  Even if you do not get sunburned, <strong>any</strong> unprotected exposure to the sun <strong>adds up</strong> over time and causes <strong>skin cancer</strong>.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <IonImg src="/assets/images/cc/Sun damage.png"></IonImg>
            
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc6")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC5;
