import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../../../Fonts.css";
import Accordion from "../../../components/accordion/Accordion";
import LoadingScreen from "../../../components/loading_screen/LoadingScreen";
import SummaryDescription from "../../../components/summary_description/SummaryDescription";
import { C2DataSubmissionService } from "../../../services/C2DataSubmissionService";
import { DataService } from "../../../services/DataService";
import { UserService } from "../../../services/UserService";
import { getApi } from "../../../services/dataApi";
import "./CoresTab.css";

interface CoresTabProps {
  coresTabFlag: boolean;
  setCoresTabFlag: Function;
}

let openedIndex = -1;

const CoresTab: React.FC<CoresTabProps> = ({
  coresTabFlag,
  setCoresTabFlag,
}) => {
  // console.log(DataService.coresDataJSON);
  const [coursedata, setcourseData] = useState({ cores: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  const fetchData = async () => {
    try {
      //setcourseData(responseData);
      //DataService.coresDataJSON = responseData;
      //console.log(DataService.coresDataJSON);
      openLoader();
      const responseData = await getApi("cores-rest");
      if (responseData != undefined) {
        setcourseData(responseData);
        DataService.coresDataJSON = responseData;
        closeLoader();

        navigateToCoreFromLearnNow();
        // console.log(DataService.coresDataJSON);
      }
      return;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!UserService.isControlContentRole) {
      if (
        DataService.getCoresFromBE &&
        (DataService.refreshCoresFromBE || coresTabFlag)
      ) {
        DataService.refreshCoresFromBE = false;
        fetchData();
        setCoresTabFlag(false);
      }
    }
  });

  const router = useIonRouter();

  const navigateToControlContent = () => {
    DataService.currentCoreIndex = 1;

    DataService.isLesson = false;
    const screens = DataService.getScreens(
      DataService.currentCoreIndex,
      DataService.currentChapterIndex
    );
    DataService.currentCoreScreen = (screens as [any])[0];
    DataService.currentCoreScreens = screens;
    DataService.navigateToCore1 = false;
    DataService.navigateToCore2 = false;
    router.push("/corechaptercontents", "forward", "push");
  };

  const resetCore2Ch3Content = () => {
    C2DataSubmissionService.c2Ch3S1IncDec = 0;
    C2DataSubmissionService.c2Ch3S1SinMCQ1 = "";
    C2DataSubmissionService.c2Ch3S1SinMCQ2 = "";
  };

  const resetCore2Ch3TanContent = () => {
    C2DataSubmissionService.c2Ch3S1IncTanDec = 0;
    C2DataSubmissionService.c2Ch3S1SinTanMCQ1 = "";
    C2DataSubmissionService.c2Ch3S1SinTanMCQ2 = "";
  };

  const navigateToCoreFromLearnNow = () => {
    if (DataService.navigateToCore1 || DataService.navigateToCore2) {
      if (DataService.navigateToCore1) {
        console.log("Learn how core 1");
        DataService.currentCoreIndex = 0;
      } else if (DataService.navigateToCore2) {
        DataService.currentCoreIndex = 1;
      }
      DataService.isLesson = false;
      const screens = DataService.getScreens(
        DataService.currentCoreIndex,
        DataService.currentChapterIndex
      );
      DataService.currentCoreScreen = (screens as [any])[0];
      DataService.currentCoreScreens = screens;
      DataService.navigateToCore1 = false;
      DataService.navigateToCore2 = false;
      router.push("/corechaptercontents", "forward", "push");
    }
  };

  const navigatetoChapter = (
    screens: any,
    coreNo: number,
    chapterNo: string
  ) => {
    // resetCore2Ch3Content();
    // resetCore2Ch3TanContent();

    DataService.currentCoreIndex = coreNo;
    DataService.currentChapterIndex = Number(chapterNo.split(" ")[1]) - 1;
    if (
      coreNo === 0 &&
      (chapterNo === "Goal Setting" || chapterNo === "Activity")
    ) {
      //Temp solution
      DataService.currentChapterIndex = 6;
    } else if (
      coreNo === 1 &&
      (chapterNo === "Goal Setting" || chapterNo === "Activity")
    ) {
      //Temp solution
      DataService.currentChapterIndex = 4;
      // DataSubmissionService.c2A1SkinSelfCheckGoals = {
      //   goals: DataSubmissionService.c2A1SelfCheckGoalsGoalsInitVal,
      // };
    }
    DataService.coreOpenedFromCoresTab = true;
    DataService.isLesson = false;
    DataService.currentCoreScreen = screens[0];
    DataService.currentCoreScreens = screens;
    router.push("/corechaptercontents", "forward", "push");
  };

  return (
    <IonPage>
      {!UserService.isControlContentRole && (
        <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
          <IonGrid>
            <IonRow class="titleCenter">
              {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
              <IonText className="titleText">Sections</IonText>
              {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
            </IonRow>
          </IonGrid>
          <div className="mainContent" style={{ paddingTop: "16px" }}>
            {/* {coursedata.map((core, index) => (
          <li> ..</li>
        ))} */}
            <LoadingScreen text="Loading Sections" isOpen={showLoader} />
            {DataService.coresDataJSON &&
              DataService.coresDataJSON.cores.map((core, index) => (
                <Accordion
                  coreNo={index}
                  key={Math.random()}
                  title={core.title}
                  description={core.description}
                  imageUrl={core.imageUrl}
                  chapters={core.chapters}
                  click={navigatetoChapter}
                  isOpened={openedIndex === index}
                  isOpenedClicked={() => {
                    openedIndex = index;
                    setRefresh(!refresh);
                  }}
                />
              ))}
          </div>
        </IonContent>
      )}

      {UserService.isControlContentRole && (
        <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
          <IonGrid>
            <IonRow class="titleCenter">
              {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
              <IonText className="titleText">Page 1</IonText>
              {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
            </IonRow>
          </IonGrid>
          <div className="mainContent" style={{ paddingTop: "32px" }}>
            {/* <IonText style={{ color: "black" }}>Intro</IonText> */}

            {/* Main body here */}
            <IonText className="ion-margin-bottom default-text cccoContentHeading">
              <strong>Welcome to mySmartSkin!</strong>
            </IonText>
            <div style={{ width: "100%", height: "16px" }} />

            <IonImg src="/assets/images/cc/welcome.svg"></IonImg>
            <div style={{ width: "100%", height: "16px" }} />


            {/* Main body here */}
            <div className="ccaDescTextContainer">
              <IonText
                className="ccaDescText"
                dangerouslySetInnerHTML={{
                  __html: "<strong>mySmartSkin</strong> is a program specifically designed for people like you who have been diagnosed with melanoma.",
                }}
              ></IonText>
            </div>
            <div style={{ width: "100%", height: "16px" }} />
            <div className="ccaDescTextContainer">
              <IonText
                className="ccaDescText"
                dangerouslySetInnerHTML={{
                  __html: "Many people diagnosed with melanoma find it <strong>hard to check their skin</strong> on a regular basis for any potential growths or changes that may be skin cancer.  People also struggle to <strong>protect themselves sufficiently from the sun<strong>.",
                }}
              ></IonText>
            </div>
            <div style={{ width: "100%", height: "16px" }} />
            <div className="ccaDescTextContainer">
              <IonText className="ccaDescText">mySmartSkin will help you:
                <ul style={{ marginLeft: "16px" }}>
                  <li>Learn why checking your skin and sun-safe habits are important and worth doing​</li>
                  <li>Learn how to check your skin thoroughly and efficiently​​</li>
                  <li>Learn how to become more sun-safe and protect your skin from sun damage​</li>
                </ul>
              </IonText>
            </div>
            <div style={{ width: "100%", height: "140px" }} />
            {/* Continue Button */}
            <div className="buttonFooter" style={{ left: "0px" }}>
              <IonGrid>
                <IonRow>
                  <IonCol
                    size="12"
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    enableNextBtn && (
                    <IonButton
                      className="loginBtnEnabled"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        router.push("/cc0")
                      }}
                    >
                      Continue
                    </IonButton>
                    )
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default CoresTab;
