import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import { useState } from "react";
import "./CC2.css";
import AccordianTextCC from "../../../components/accordian_text_cc/AccordianTextCC";

const CC2: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {

  // }, [])

  const navigateBack = () => {
    router.goBack();
  };

  const [accordionData, setAccordianData] = useState([
    {
      title: "Examine your face",
      imageUrl: "/assets/images/cc/Face.png",
      description:
        "Check the inside and outside of your nose, lips, mouth, eyes and ears.",
      open: false,
    },
    {
      title: "Inspect your scalp",
      imageUrl: "/assets/images/cc/Scalp.png",
      description:
        "Check each section of your scalp by parting your hair into sections.  Consider using a blow dryer or comb.",
      open: false,
    },
    {
      title: "Scan your arms",
      imageUrl: "/assets/images/cc/Arms.png",
      description:
        "Lift your arms and examine them all the way down to your hands where you will check the palm and back of your hand, fingers, and fingernails.  Don’t forget about your elbows or armpits.​​",
      open: false,
    },
    {
      title: "Inspect your torso",
      imageUrl: "/assets/images/cc/Torso.png",
      description: "Thoroughly check your neck, chest and torso.​​",
      open: false,
    },
    {
      title: "Scan your upper back",
      imageUrl: "/assets/images/cc/UpperBack.png",
      description:
        "Use your mirrors to inspect the back of your neck, shoulders and upper back.​​",
      open: false,
    },
    {
      title: "Scan your lower back​",
      imageUrl: "/assets/images/cc/LowerBack.png",
      description:
        "Still using your mirrors, scan your lower back, buttocks and back of legs.​​",
      open: false,
    },
    {
      title: "Inspect your legs and genitals​",
      imageUrl: "/assets/images/cc/Genitals.png",
      description:
        "Finally, work down your legs checking your genitals, legs, feet, heels, toes, soles and toenails.​​",
      open: false,
    },
    {
      title: "Scan your lower back​",
      imageUrl: "/assets/images/cc/LowerBack.png",
      description:
        "Still using your mirrors, scan your lower back, buttocks and back of legs.​​",
      open: false,
    },
  ]);

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
            <IonText className="titleText">Page 4</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
          {/* Main body here */}
          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            How To Do a Skin Self-Check
          </IonText>
          <div style={{ width: "100%", height: "16px" }} />
          <IonText className="ccaDescText">
          We will now walk you through how to do a skin self-check. Click on each arrow to learn more about each body part.
          </IonText>

          {accordionData.map((option: any, i: number) => {
            return (
              <AccordianTextCC
                key={`acccc2${i}`}
                title={option.title}
                imageUrl={option.imageUrl}
                description={option.description}
                open={option.open}
                isOpened={(open: boolean) => {
                  let tempAccOpenStates: any[] = [];

                  for (let index = 0; index < accordionData.length; index++) {
                    let element = accordionData[index];
                    element.open = false;
                    tempAccOpenStates.push(element);
                  }

                  if (open) {
                    tempAccOpenStates[i].open = true;
                  }
                  setAccordianData(tempAccOpenStates);
                  // setRefresh(!refresh);
                }}
              />
            );
          })}
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc3");
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC2;
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
