import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";


import { useState } from "react";

const CC6: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const navigateBack = () => {
    router.goBack();
  };




  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                  className="settingsIcon"
                  icon={settingsOutline}
                  style={{ color: "transparent" }}
                ></IonIcon> */}
            <IonText className="titleText">Page 8​</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>

          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Sun-Safe Behaviors
          </IonText>
          <div style={{ width: "100%", height: "16px" }} />
          <IonRow>
              <IonCol>
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "Consider the following <strong>sun-safe behaviors</strong> to protect your skin from damage:",
              }}
            ></IonText>
            </IonCol>
            </IonRow>
          {/* Main body here */}
          {/* <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">

              Consider the following <strong>sun safe behaviors</strong> to protect your skin from damage:
              <ul style={{ marginLeft: "16px" }}>
                <li>Avoid being outside between <span style={{ color: '#ff2020' }}><strong>10 am and 4 pm</strong></span> since that is when the sun is the strongest​​</li>
                <li>Apply and reapply <span style={{ color: '#f29648' }}><strong>sunscreen</strong></span> of SPF 30 or higher​</li>
                <li>Wear <span style={{ color: '#ffc40c' }}><strong>sunglasses</strong></span> to protect your eyes​​</li>
                <li>Wear a <span style={{ color: '#91b44e' }}><strong>wide brim hat</strong></span> with a brim of at least 3 inches​​</li>
                <li>Wear <span style={{ color: '#5b84cb' }}><strong>long sleeves and pants</strong></span> ​​ </li>
                <li><span style={{ color: '#824aac' }}><strong>Seek shade</strong></span> when outside​</li>
                <li>Do not go <span style={{ color: '#b56ca1' }}><strong>indoor tanning</strong></span> or tan outside on purpose​​</li>
              </ul>
            </IonText>
          </div> */}
           <div style={{ width: "100%", height: "16px" }} />
          <IonGrid>
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Time.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Avoid being outside between <span className="cc6TextColor1"><strong>10 am and 4 pm</strong></span> since that is when the sun is the strongest​
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Sunscreen.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Apply and reapply <span className="cc6TextColor2"><strong>sunscreen</strong></span> of SPF 30 or higher
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Sunglasses.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Wear <span className="cc6TextColor3"><strong>sunglasses</strong></span> to protect your eyes
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Hat.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Wear a <span className="cc6TextColor4"><strong>wide brim hat</strong></span> with a brim of at least 3 inches​​
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Clothes.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Wear <span className="cc6TextColor5"><strong>long sleeves and pants</strong></span> 
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Umbrella.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText"><span className="cc6TextColor6"><strong>Seek shade</strong></span> when outside
                          </IonText>
              </IonCol>
            </IonRow>
            <div style={{ width: "100%", height: "16px" }} />
           
            <IonRow>
              <IonCol size="auto">
                <IonImg src="/assets/images/Core2Images/sb/Indoor tanning.png"></IonImg>
              </IonCol>
              <IonCol className="cc3TextCol">
                  <IonText className="ccaDescText">Do not go <span className="cc6TextColor7"><strong>indoor tanning</strong></span> or tan outside on purpose</IonText>
              </IonCol>
            </IonRow>
            {/* <div style={{ width: "100%", height: "16px" }} /> */}
            {/* <div className="ccaDescTextContainer"> */}
            {/* <IonRow>
              <IonCol>
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "If you find any <strong>unusual</strong> or <strong>abnormal</strong> spots, make sure to consult with your doctor.",
              }}
            ></IonText>
            </IonCol>
            </IonRow> */}
            <div style={{ width: "100%", height: "150px" }} />
          {/* </div> */}
          </IonGrid>

          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc7")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC6;
