import axios from "axios";
import { UserService } from "./UserService";
import { SkinSelfCheckDataService } from "./SkinSelfCheckDataService";
import { AppInfoTimeService } from "./AppInfoTimeService";

////Prod
// const apiUrl = "https://prod.mss.radiant.digital";

/// Dev
const apiUrl = "https://stage.mss.radiant.digital";

// export async function getApi(endpoint: string): Promise<any> {
//   const url = `${apiUrl}/${endpoint}`;
//   const response = await axios.get(url);
//   return response.data;
// }
export async function getApi(endpoint: string): Promise<any> {
  try {
    const res = await axios.request({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      url: apiUrl + "/" + endpoint,
    });
    return res.data;
  } catch (error) {
    return;
  }
}

export async function loginApi(
  username: string,
  password: string,
  responseCallback: Function
) {
  let obj: any = {
    name: username,
    pass: password,
  };

  const loginUrl = apiUrl + "/user/login?_format=json";
  // Make a POST request to the login endpoint
  fetch(loginUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Specify the content type
    },
    // credentials: 'include',
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      console.log(response.headers);
      let respHeaders = response.headers.get("Content-Length");
      let cookie = response.headers.get("Set-Cookie");
      console.log(respHeaders);
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Login success:", responseData);
      if (responseData.message) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

// Progress

export async function postProgress(obj: any, responseCallback: Function) {
  // https://stage.mss.radiant.digital/progresscreatechart?_format=json
  const progressPostUrl = apiUrl + "/progresscreatechart?_format=json"; // + `&t=${Date.now()}`;

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        alert("Posting progress failed!! Please try again!!");

        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);

      alert("Posting progress failed!! Please try again!!");

      responseCallback(false, error);
    });
}

export async function getProgress(responseCallback: Function) {
  // https://stage.mss.radiant.digital/progress-chart/3?_format=json
  const progressUrl =
    apiUrl +
    "/progress-chart/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Login success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

// export async function postSkinSelfCheckData(
//   obj: any,
//   responseCallback: Function
// ) {
//   const progressPostUrl = apiUrl + "/node?_format=json"; //"/addskinselfcheck/createssc?_format=json";

//   const body = {
//     type: [
//       {
//         target_id: "skinselfcheck",
//       },
//     ],
//     title: [
//       {
//         value: "ProfileTab",
//       },
//     ],
//     field_payload: [
//       {
//         value: obj,
//       },
//     ],
//   };

export async function postSkinSelfCheckData(
  obj: any,
  responseCallback: Function
) {
  const progressPostUrl = apiUrl + "/skiselfcheck_payload/?_format=json";

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      // username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

///////////////////////////////////Post SSC 2///////////////////////////////////////////
// Dashboard - Post SSC 2
export const postSkinSelfCheckData2 = async (
  sscObj: any,
  responseCallback: Function
) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  SkinSelfCheckDataService.skinselfCheckCompletedPartNames =
    SkinSelfCheckDataService.calculateSkinSelfCompletedNames(sscObj);

  SkinSelfCheckDataService.skinselfCheckCompletedImages =
    SkinSelfCheckDataService.calculateSkinSelfCompletedImages(sscObj);
  const commaSeparatedPartsString =
    SkinSelfCheckDataService.skinselfCheckCompletedPartNames.join("|");
  var images = SkinSelfCheckDataService.concatArrwithDelimiter(
    SkinSelfCheckDataService.skinselfCheckCompletedImages,
    "|"
  );

  const sscCount = SkinSelfCheckDataService.skinselfCheckCount;

  const obj = {
    type: [
      {
        target_id: "skiselfpayload",
      },
    ],
    title: [
      {
        value: "skin self check",
      },
    ],
    field_skinselfpayload: [
      {
        value: JSON.stringify(sscObj),
      },
    ],
    field_total: [
      {
        value: sscObj.bodyCountDenominator.toString(),
      },
    ],
    field_completed: [
      {
        value: sscObj.bodyCountNumerator.toString(),
      },
    ],
    field_completed_parts: [
      {
        value: commaSeparatedPartsString,
      },
    ],
    field_image_uploaded: [
      {
        value: images,
      },
    ],
    field_times_revisited: [
      {
        value: sscCount.toString(),
      },
    ],
  };
  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // “credentials”: true,
        "Content-Type": "application/json",
        // Accept: “application/json”,
        Authorization: `Basic ${btoa(btoaForm)}`,
        // “X-CSRF-Token”: UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });
    if (!response.ok) {
      alert("Skin Self Check posting failed!! Please try again!!")
      responseCallback(false);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    let nid = data.nid[0].value;
    console.log(nid);
    SkinSelfCheckDataService.skinSelfCheckNID = nid;
    SkinSelfCheckDataService.isSSCReset = false;

    responseCallback(true);
  } catch (error) {
    alert("Skin Self Check posting failed!! Please try again!!")
    console.error("Error fetching data:", error);
    responseCallback(false);
  }
};

// Dashboard - Patch SSC 2
export const patchSkinSelfCheckData2 = async (
  sscObj: any,
  responseCallback: Function
) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  let nid = SkinSelfCheckDataService.skinSelfCheckNID.toString();

  SkinSelfCheckDataService.skinselfCheckCompletedPartNames =
    SkinSelfCheckDataService.calculateSkinSelfCompletedNames(sscObj);

  SkinSelfCheckDataService.skinselfCheckCompletedImages =
    SkinSelfCheckDataService.calculateSkinSelfCompletedImages(sscObj);
  const commaSeparatedPartsString =
    SkinSelfCheckDataService.skinselfCheckCompletedPartNames.join("|");
  var images = SkinSelfCheckDataService.concatArrwithDelimiter(
    SkinSelfCheckDataService.skinselfCheckCompletedImages,
    "|"
  );

  const sscCount = SkinSelfCheckDataService.skinselfCheckCount;

  const obj = {
    type: [
      {
        target_id: "skiselfpayload",
      },
    ],
    title: [
      {
        value: "skin self check",
      },
    ],
    field_skinselfpayload: [
      {
        value: JSON.stringify(sscObj),
      },
    ],
    field_total: [
      {
        value: sscObj.bodyCountDenominator.toString(),
      },
    ],
    field_completed: [
      {
        value: sscObj.bodyCountNumerator.toString(),
      },
    ],
    field_completed_parts: [
      {
        value: commaSeparatedPartsString,
      },
    ],
    field_image_uploaded: [
      {
        value: images,
      },
    ],
    field_times_revisited: [
      {
        value: sscCount.toString(),
      },
    ],
  };

  try {
    //https://stage.mss.radiant.digital/node/2590?_format=json
    const response = await fetch(apiUrl + "/node/" + nid + "?_format=json", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      alert("Skin Self Check posting failed!! Please try again!!")
      throw new Error("Network response was not ok");
    }

    console.log("Success!!");
    responseCallback(true);
  } catch (error) {
    alert("Skin Self Check posting failed!! Please try again!!")
    console.error("Error fetching data:", error);
    responseCallback(false);
  }
};

//////////get SSC 2 NID/////////////////
export async function getSSC2NID(responseCallback: Function) {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  const progressUrl = apiUrl + "/api/get-skin-self-check-id";
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      // "credentials": true,
      "Content-Type": "application/json",
      // Accept: "application/json",
      Authorization: `Basic ${btoa(btoaForm)}`,
      // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      // userid: UserService.userId,
      // username: UserService.userName,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log("Lock unLock resp", resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Lock UnLock success:", responseData);
      if (responseData) {
        const nid = responseData[0].nid;
        SkinSelfCheckDataService.skinSelfCheckNID = nid;

        responseCallback(true, responseData);
      } else {
        responseCallback(false, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function postSelectedPrizeData(
  obj: any,
  responseCallback: Function
) {
  const progressPostUrl =
    apiUrl + "/selectprizes_checkbox/createprizes?_format=json";

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      // username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

// export async function getSkinSelfCheckData(responseCallback: Function) {
//   const progressUrl = apiUrl + "/get-skin-self-check/" + UserService.userId;
//   // Make a POST request to the login endpoint
//   fetch(progressUrl, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       "X-CSRF-Token": UserService.csrfToken, // Specify the content type
//     }, // Convert data to JSON string
//   })
//     .then((response) => {
//       if (!response.ok) {
//         //throw new Error("Network response was not ok");
//       }
//       let resp = response.json();
//       console.log(resp);
//       return resp;
//     })
//     .then((responseData) => {
//       // Handle the login response here
//       console.log("SkinCheck success:", responseData);
//       if (responseData.message !== undefined) {
//         responseCallback(false, responseData);
//       } else {
//         responseCallback(true, responseData);
//       }
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//       responseCallback(false, error);
//     });
// }

export async function getLockUnLockPrize(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/prizes-section1/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log("Lock unLock resp", resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Lock UnLock success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(true, responseData);
      } else {
        responseCallback(false, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getLockUnLockBadze(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/badges_get_payload/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log("Lock unLock Badze resp", resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Lock UnLock Badze success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(true, responseData);
      } else {
        responseCallback(false, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getSubmittedPrizeDetails(
  prizetitle: string,
  responseCallback: Function
) {
  const progressUrl =
    apiUrl +
    "/selectedprizescore1-completion-get/" +
    UserService.userId +
    "/" +
    prizetitle +
    "?_format=json";
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log("Prize details resp", resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Prize details success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getSkinSelfCheckData(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/skiselfcheck_get_payload/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("SkinCheck success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

////////////////////////PROFILE///////////////////////////
export async function postProfileData(responseCallback: Function) {
  const progressPostUrl = apiUrl + "/profile_tab/createchapters?_format=json";

  let obj = {
    notifications: UserService.turnOnNotifications,
    showHints: UserService.showHints,
    skinColorPalette:
      SkinSelfCheckDataService.skinColor.length > 0
        ? SkinSelfCheckDataService.skinColor
        : "#D8E7FD",
  };

  // let obj = {
  //   type: [{ target_id: "profiletab" }],
  //   title: [{ value: "profiletab" }],
  //   field_notifications: [{ value: UserService.turnOnNotifications }],
  //   field_showhints: [{ value: UserService.showHints }],
  //   field_skincolorpalette: [{ value: SkinSelfCheckDataService.skinColor }],
  // };

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getProfileData(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/profiletab-data/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("SkinCheck success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function postProfileData2(responseCallback: Function) {
  const progressPostUrl = apiUrl + "/profile_tab/createchapters?_format=json";

  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  const obj = {
    field_show_hints: [
      {
        value: UserService.showHints,
      },
    ],
    field_skin_color_palette: [
      {
        value:
          SkinSelfCheckDataService.skinColor.length > 0
            ? SkinSelfCheckDataService.skinColor
            : "#D8E7FD",
      },
    ],
    field_notifications: [
      {
        value: UserService.turnOnNotifications,
      },
    ],
  };

  try {
    const response = await fetch(
      apiUrl + "/user/" + UserService.userId + "?_format=json",
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          // "credentials": true,
          "Content-Type": "application/json",
          // Accept: "application/json",
          Authorization: `Basic ${btoa(btoaForm)}`,
          // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
          // userid: UserService.userId,
          // username: UserService.userName,
        },
        body: JSON.stringify(obj),
      }
    );

    if (!response.ok) {
      responseCallback(false, "Error posting data");
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Success!!");
    responseCallback(false, response);
  } catch (error) {
    responseCallback(false, "Error posting data");
    console.error("Error fetching data:", error);
  }
}

export async function getProfileData2(responseCallback: Function) {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;
  const progressUrl = apiUrl + "/api/my-info";
  // Make a GET request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      // “credentials”: true,
      "Content-Type": "application/json",
      // Accept: “application/json”,
      Authorization: `Basic ${btoa(btoaForm)}`,
      // “X-CSRF-Token”: UserService.csrfToken, // Specify the content type
      // userid: UserService.userId,
      // username: UserService.userName,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("Get Profile success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

////////////////////////PROFILE///////////////////////////

export abstract class Api {
  protected abstract getBaseUrl(): string;

  async fetchData(endpoint: string): Promise<any> {
    const url = this.getBaseUrl() + endpoint;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

///////////////////Core 2 Activity///////////////////////

export async function postC1A1(obj: any, responseCallback: Function) {
  const progressPostUrl =
    apiUrl + "/coreoneactivityrest/createcoreoneactivity?_format=json";

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      // username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getC1A1(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/core1activity_data/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("SkinCheck success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

///////////////////Core 1 Activity///////////////////////

export async function postC2A1(obj: any, responseCallback: Function) {
  const progressPostUrl =
    apiUrl + "/coretwoactivity/createcore2activity?_format=json";

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      // username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function getC2A1(responseCallback: Function) {
  const progressUrl =
    apiUrl +
    "/coretwoactivity-data/" +
    UserService.userId +
    "?_format=json" +
    `&t=${Date.now()}`;
  // Make a POST request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", // Specify the content type
      "X-CSRF-Token": UserService.csrfToken,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("SkinCheck success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export async function postNotificationSchedule(
  obj: any,
  responseCallback: Function
) {
  const notificationPostUrl =
    apiUrl + "/twiliosend_message/sendsms?_format=json";

  // Make a POST request to the login endpoint
  fetch(notificationPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
      // let resp = response.json();
      // console.log(resp);
      // return resp;
    })
    // .then((responseData) => {
    //   // Handle the login response here
    //   console.log("Login success:", responseData);
    //   if (responseData.message) {
    //     responseCallback(false, responseData);getProgress
    //   } else {
    //     responseCallback(true, responseData);
    //   }
    // })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

export const postNotificationSchedule2 = async (obj: any, responseCallback: Function) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  const sscObj = {
    "type": [{
    "target_id": "notifications"
    }],
    "title": [{
    "value": "User Notifications"
    }],
    "field_scheduled_date": [{
    "value": obj.dateTime
    }]
    }

  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(sscObj),
    });

    if (!response.ok) {
      responseCallback(false, "Error posting data");
      throw new Error("Network response was not ok");
    }

    responseCallback(true, response);

  } catch (error) {
    responseCallback(false, "Error posting data");
    console.error("Error fetching data:", error);
  }
};

// Forgot Password
export async function postForgotPassword(obj: any, responseCallback: Function) {
  const progressPostUrl = apiUrl + "/user/password?_format=json";

  // Make a POST request to the login endpoint
  fetch(progressPostUrl, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": UserService.csrfToken, // Specify the content type
      userid: UserService.userId,
      // username: UserService.userName,
    },
    body: JSON.stringify(obj), // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
        responseCallback(false, "Error posting data");
      } else {
        responseCallback(true, response);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}

////////////////////////////////////Dashboard App Time///////////////////////////////////////////
// Dashboard - Post App Time
export const postAppTime = async (obj: any, responseCallback: Function) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    let nid = data.nid[0].value;
    console.log(nid);

    AppInfoTimeService.appSessionTimeNid = nid;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Dashboard - Patch App Time
export const patchAppTime = async (obj: any, nid: string) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    //https://stage.mss.radiant.digital/node/2590?_format=json
    const response = await fetch(apiUrl + "/node/" + nid + "?_format=json", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Success!!");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

////////////////////////////////////Dashboard Sections///////////////////////////////////////////
// Dashboard - Post SSC Time
export const postSectionsTime = async (
  obj: any,
  responseCallback: Function
) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    let nid = data.nid[0].value;
    console.log(nid);
    AppInfoTimeService.appSectionsTimeNid = nid;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const patchSectionsTime = async (obj: any, nid: string) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    //https://stage.mss.radiant.digital/node/2590?_format=json
    const response = await fetch(apiUrl + "/node/" + nid + "?_format=json", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Success!!");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

////////////////////////////////////Dashboard SSC///////////////////////////////////////////
// Dashboard - Post SSC Time
export const postSSCTime = async (obj: any, responseCallback: Function) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    let nid = data.nid[0].value;
    console.log(nid);
    AppInfoTimeService.appSkinSelfCheckNid = nid;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const patchSSCTime = async (obj: any, nid: string) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    //https://stage.mss.radiant.digital/node/2590?_format=json
    const response = await fetch(apiUrl + "/node/" + nid + "?_format=json", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Success!!");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

////////////////////////////////////Dashboard Quiz FnF///////////////////////////////////////////
// Dashboard - Post SSC Time
export const postQuizFnf = async (obj: any, responseCallback: Function) => {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;

  try {
    const response = await fetch(apiUrl + "/node?_format=json", {
      method: "POST",
      headers: {
        Accept: "application/json",
        // "credentials": true,
        "Content-Type": "application/json",
        // Accept: "application/json",
        Authorization: `Basic ${btoa(btoaForm)}`,
        // "X-CSRF-Token": UserService.csrfToken, // Specify the content type
        // userid: UserService.userId,
        // username: UserService.userName,
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      responseCallback(false);
      throw new Error("Network response was not ok");
    }

    responseCallback(true);
  } catch (error) {
    console.error("Error fetching data:", error);
    responseCallback(false);
  }
};

export async function getSkinSelfSpotHistory(responseCallback: Function) {
  let username = localStorage.getItem("username");
  let password = localStorage.getItem("password");
  let btoaForm = username + ":" + password;
  const progressUrl = apiUrl + "/api/get-skin-self-check-data";
  // Make a GET request to the login endpoint
  fetch(progressUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      // “credentials”: true,
      "Content-Type": "application/json",
      // Accept: “application/json”,
      Authorization: `Basic ${btoa(btoaForm)}`,
      // “X-CSRF-Token”: UserService.csrfToken, // Specify the content type
      // userid: UserService.userId,
      // username: UserService.userName,
    }, // Convert data to JSON string
  })
    .then((response) => {
      if (!response.ok) {
        //throw new Error("Network response was not ok");
      }
      let resp = response.json();
      console.log(resp);
      return resp;
    })
    .then((responseData) => {
      // Handle the login response here
      console.log("SpotHistory success:", responseData);
      if (responseData.message !== undefined) {
        responseCallback(false, responseData);
      } else {
        responseCallback(true, responseData);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      responseCallback(false, error);
    });
}
