import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./HomeTab.css";
import HomeIntro from "../../../components/home_intro/HomeIntro";
import HomeCores from "../../../components/home_cores/HomeCores";
import HomeStateCompleted from "../../../components/home_state_completed/HomeStateCompleted";
import HomeSkinSelfCheck from "../../../components/home_skin_self_check/HomeSkinSelfCheck";
import SkinCheckProgress from "../../../components/skin_check_progress/SkinCheckProgress";
import { useEffect, useRef, useState } from "react";
import HomeCoreProgress from "../../../components/home_core_progress/HomeCoreProgress";
import {
  getProfileData,
  getProfileData2,
  getProgress,
  getSSC2NID,
  getSkinSelfCheckData,
  getSkinSelfSpotHistory,
  postProgress,
} from "../../../services/dataApi";
import { ProgressDataService } from "../../../services/ProgressDataService";
import { DataService } from "../../../services/DataService";
import LoadingScreen from "../../../components/loading_screen/LoadingScreen";
import { UserService } from "../../../services/UserService";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";
import { format } from "date-fns";
import { AppInfoTimeService } from "../../../services/AppInfoTimeService";
import SkinSelfCheckPartSel from "../../skin_self_check_part_sel/SkinSelfCheckPartSel";

let lastCompletedIndexOfCore1 = -1;
let lastCompletedIndexOfCore2 = -1;

const HomeTab: React.FC = () => {
  // const progresses = [
  //   {
  //     month: "Aug",
  //     percent: 100,
  //   },
  //   {
  //     month: "Sept",
  //     percent: 100,
  //   },
  //   {
  //     month: "Oct",
  //     percent: 0,
  //   },
  //   {
  //     month: "Nov",
  //     percent: 100,
  //   },
  //   {
  //     month: "Dec",
  //     percent: 20,
  //   },
  // ];

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollIconRef = useRef<HTMLIonImgElement | null>(null);
  let scrollThroughBtn = false;
  const [clickCount, setClickCount] = useState(0);
  const [btnText, setBtntext] = useState("2nd Scenario");
  const [refresh, setRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Loading");
  const [progresses, setProgresses] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    format(new Date().getTime(), "MMMM")
  );
  const [skinPercent, setSkinPercent] = useState(0);
  var router = useIonRouter();

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  const calculateCompletedBodyCount = (obj: any) => {
    var bodymap = obj;
    SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount = 0;
    for (var i = 0; i < bodymap.regions.length; i++) {
      var parts = bodymap.regions[i].parts;
      for (var j = 0; j < parts.length; j++) {
        var localpart = parts[j];
        for (var k = 0; k < localpart.parts.length; k++) {
          var localinnerpart = localpart.parts[k];
          if ("spots" in localinnerpart || localpart.isChecked == true) {
            SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount =
              SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount + 1;
            break;
          }
        }
      }
    }
    return SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount;
  };


  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrolling = () => {
    console.log("SCROLLLLLING");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };
  const moveToTour = () => {
    router.push("/skinchecktour", "forward", "push");
  };

  const scrollingEnd = () => {
    console.log("SCROLLLLLING ENDDDD");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "visible";
    }
  };

  const handleDispatchEvent = () => {
    // Create a custom event
    const customEvent = new CustomEvent("tab-event-tab3", {
      detail: {
        message: "Hello from custom event!",
      },
    });

    // Dispatch the custom event on a DOM element
    const targetElement = document.getElementById("target-element"); // Replace with your target element's ID or reference
    if (targetElement) {
      targetElement.dispatchEvent(customEvent);
    }
  };

  const homeScenario = () => {
    let count = clickCount + 1;
    setClickCount(count);
    if (count === 1) {
      setBtntext("3rd Scenario");
    } else if (count === 2) {
      setBtntext("1st Scenario");
    } else if (count === 3) {
      setBtntext("2nd Scenario");
      setClickCount(0);
    }
  };

  async function getProfile() {
    setLoaderText("Getting User Profile Settings");
    try {
      openLoader();
      const responseData = await getProfileData2(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log(respData);
            //Save user data
            UserService.turnOnNotifications = respData[0].notifications === "0" ? false : true;
            //respData.notifications;
            UserService.showHints = respData[0].show_hints === "0" ? false : true;
            SkinSelfCheckDataService.skinColor = respData[0].skin_color_palette;
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(respData);
          }
          getSkinSelfCheck();
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error fetching data:", error);
    }
  }
  async function getSkinSelfCheck() {
    try {
      setLoaderText("Loading Skin Self Check Data");
      openLoader();
      const responseData = await getSkinSelfCheckData(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Skinselfcheck Response ---->>> " + respData);

            if (respData.length > 0) {
              let parsedJSON = JSON.parse(respData);
              var serverDateString = parsedJSON.skinselfCheckSubmittedDate;
              if (serverDateString != null && serverDateString.length > 0) {
                var serverDate = new Date(serverDateString);
                var value = 1000 * 60 * 60 * 24;
                var diff = new Date().getTime() - serverDate.getTime();
                var result = Math.ceil(diff / value);
                console.log("diff....",diff);
                console.log("diff result....",result);
                if (result < 0) {
                  result = 0;
                }
                if(result<SkinSelfCheckDataService.skinSelfCheckDataResetValue){
                  SkinSelfCheckDataService.bodyMap = parsedJSON;
                  SkinSelfCheckDataService.skinselfCheckCount=1;
                  SkinSelfCheckDataService.isSSCReset = false;
                }else{
                  SkinSelfCheckDataService.bodyMap =
                  SkinSelfCheckDataService.bodyMapInitial;
                  SkinSelfCheckDataService.skinselfCheckCount=
                  SkinSelfCheckDataService.skinselfCheckCount+1;
                  SkinSelfCheckDataService.isSSCReset = true;
                }
              }else{
                SkinSelfCheckDataService.bodyMap = parsedJSON;
                SkinSelfCheckDataService.skinselfCheckCount=1;
                SkinSelfCheckDataService.isSSCReset = false;
              }
              console.log("calculateSkinselfcheckount",SkinSelfCheckDataService.skinselfCheckCount)
              let map = SkinSelfCheckDataService.bodyMap;
              console.log(map);
              
            } else {
              
              console.log("Skin Self Check JSON empty");
            }
            getSpotHistory();
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Skinselfcheck Response not success ---->>> " + respData
            );

            if (respData.message === "No data exists for this user") {
              SkinSelfCheckDataService.isSSCReset = true;
            }
            getSpotHistory();
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck Response - Error fetching data:", error);
    }
  }

  async function getSpotHistory() {
    try {
      setLoaderText("Loading Spot History");
      openLoader();
      const responseData = await getSkinSelfSpotHistory(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get SpotHistory Response ---->>> " + respData);
            if (respData.length > 0) {
              SkinSelfCheckDataService.historybodyMap=respData;
              var tempArr =
                SkinSelfCheckDataService.calculateSkinSelfCheckProgress();
              setProgresses(tempArr);
              var currentMonth = format(new Date().getTime(), "MMM");
              var count = 0;
              for (var i = 0; i < tempArr.length; i++) {
                var localprogress = tempArr[i];
                if (currentMonth === localprogress["month"]) {
                  count = localprogress["percent"];
                  break;
                }
              }
              setSkinPercent(count);
              console.log("skin percent", skinPercent);

              
            } else {
              console.log("Get Spot History JSON empty");
            }
            getSkinSelfCheckNID();
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Spot History Response not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Spot History Response - Error fetching data:", error);
    }
  }

  async function getSkinSelfCheckNID() {
    try {
      openLoader();
      const responseData = await getSSC2NID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Skinselfcheck Response ---->>> " + respData);

            if (respData.length > 0) {
              let parsedJSON = JSON.parse(respData);
              console.log(parsedJSON);
            } else {
              console.log("Skin Self Check NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Skinselfcheck NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }

  async function postProgressData(obj: any) {
    setLoaderText("Saving Sections' Progress");
    try {
      // openLoader();
      const responseData = await postProgress(
        obj,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            // closeLoader();
            //Save user data
          } else if (!isSuccess) {
            //Error
            // closeLoader();
            console.log(respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error fetching data:", error);
    }
  }
  async function getCoresProgress() {
    setLoaderText("Loading Sections' Progress");
    openLoader()!;
    try {
      const responseData = await getProgress(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();

            console.log("Progress Response ---->>> " + respData);
            // let respJSON = JSON.parse(respData);
            // console.log(respJSON);
            ProgressDataService.progressObj = respData;
            ProgressDataService.updateLocalProgressFromGet();
            updateScenario();
          } else if (!isSuccess) {
            //Error
            closeLoader();
            ProgressDataService.getProgressJSONInit();
            updateScenario();

            // if (respData.message === "No data exists for this user") {
            //   // post all 0s

            // }
            console.log("Progress Response not success ---->>> " + respData);
          }

          //Get Profile Data
          getProfile();
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Progress Response - Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (!ProgressDataService.isGetCalled) {
      ProgressDataService.isGetCalled = true;
      getCoresProgress();
    }
    updateScenario();
  });

  const updateScenario = () => {
    let progData =
      ProgressDataService.progressObj.cores.length > 1
        ? ProgressDataService.progressObj
        : ProgressDataService.progressObjTemp;

    let core1 = progData.cores[0];
    let chapter1 = core1.chapters[1];
    let core1Ch1Prog = chapter1.progress;

    let core2 = progData.cores[1];
    let chapter5 = core2.chapters[5];
    let core2Ch5Prog = chapter5.progress;

    let isFirstTime = core1Ch1Prog === 0;
    let isCoreProgressStarted = core1Ch1Prog === 100;
    let isCoreProgressFinished = core2Ch5Prog === 100;

    DataService.coresProgressStarted = isCoreProgressStarted;

    if (isFirstTime) {
      setClickCount(0);
    }

    if (isCoreProgressStarted) {
      if (isCoreProgressFinished) {
        setClickCount(2);
      } else {
        // let lastCompletedIndexOfCore1 = -1
        // let lastCompletedIndexOfCore2 = -1

        for (let index = 0; index < core1.chapters.length; index++) {
          const chapter = core1.chapters[index];
          const progress = chapter.progress;

          if (progress === 100) {
            lastCompletedIndexOfCore1 = index;
          }
        }

        if (lastCompletedIndexOfCore1 === 6) {
          if (core1.chapters[0].progress === 100) {
            lastCompletedIndexOfCore1 = 7;
          } else {
            lastCompletedIndexOfCore1 = 6;
          }
        }

        if (
          lastCompletedIndexOfCore1 === 7 &&
          core1.chapters[7].progress === 100
        ) {
          lastCompletedIndexOfCore1 = 8;
        }

        console.log(
          "lastCompletedIndexOfCore1 >>>> " + lastCompletedIndexOfCore1
        );

        for (let index = 0; index < core2.chapters.length; index++) {
          const chapter = core2.chapters[index];
          const progress = chapter.progress;

          if (progress === 100) {
            lastCompletedIndexOfCore2 = index;
          }
        }

        if (lastCompletedIndexOfCore2 === 4) {
          if (core2.chapters[0].progress === 100) {
            lastCompletedIndexOfCore2 = 5;
          } else {
            lastCompletedIndexOfCore2 = 4;
          }
        }

        if (
          lastCompletedIndexOfCore2 === 5 &&
          core2.chapters[5].progress === 100
        ) {
          lastCompletedIndexOfCore2 = 6;
        }

        if (lastCompletedIndexOfCore2 === -1) {
          lastCompletedIndexOfCore2 = 0;
        }

        console.log(
          "lastCompletedIndexOfCore2 >>>> " + lastCompletedIndexOfCore2
        );

        setClickCount(1);
      }
    }
  };

  const navigateToCurrentChapter = (core: number) => {
    if (core === 1) {
      //Start Sections Timer
      AppInfoTimeService.sendAppSectionsTimeToBE(1);

      DataService.navigateToCore1 = true;
      DataService.navigateToCore2 = false;
      DataService.refreshCoresFromBE = true;
      DataService.currentCoreIndex = 0;
      DataService.currentChapterIndex = lastCompletedIndexOfCore1;
      router.push("/home/corestab", "root", "replace");
    } else {
      //Start Sections Timer
      AppInfoTimeService.sendAppSectionsTimeToBE(2);

      DataService.navigateToCore1 = false;
      DataService.navigateToCore2 = true;
      DataService.refreshCoresFromBE = true;
      DataService.currentCoreIndex = 1;
      DataService.currentChapterIndex = lastCompletedIndexOfCore2;
      router.push("/home/corestab", "root", "replace");
    }
  };

  // const getCoreLessonName = (core: number) => {
  //   let retVal = -1;
  //   if (core === 1) {
  //     let coresJson = DataService.coresDataJSON;

  //     if (coresJson !== undefined) {
  //       let cores = coresJson.cores;
  //       let core1 = cores[0];
  //       let core1Chapters = core1.chapters;
  //       console.log(
  //         "lastCompletedIndexOfCore1 +++ " + lastCompletedIndexOfCore1
  //       );
  //       let core1CurrChapter = core1Chapters[lastCompletedIndexOfCore1 - 1];
  //       let title = core1CurrChapter.title;

  //       console.log(title);
  //       retVal = title;
  //     }
  //   } else {
  //     let coresJson = DataService.coresDataJSON;

  //     if (coresJson !== undefined) {
  //       let cores = coresJson.cores;
  //       let core2 = cores[1];
  //       let core2Chapters = core2.chapters;
  //       console.log(
  //         "lastCompletedIndexOfCore2 +++ " + lastCompletedIndexOfCore2
  //       );
  //       let core2CurrChapter = core2Chapters[lastCompletedIndexOfCore2 - 1];
  //       let title = core2CurrChapter.title;

  //       console.log(title);
  //       retVal = title;
  //     }
  //   }

  //   return retVal.toString();
  // };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
        onIonScrollStart={scrolling}
        onIonScrollEnd={scrollingEnd}
      >
        <LoadingScreen text={loaderText} isOpen={showLoader} />

        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">MySmartSkin</IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                {
                  homeScenario();
                }
              }}
            >
              {/* <IonText>{btnText}</IonText> */}
            </IonCol>
          </IonRow>
        </IonGrid>
        <div
          className="mainContent"
          style={{
            marginTop: "16px",
            paddingBottom: "16px",
            paddingTop: "16px",
          }}
        >
          {/* <div className="htScrollIconContainer">
            <IonImg
              src="assets/icons/FixedScroll.svg"
              className="htScrollIcon"
              onClick={scrollToBottom}
              ref={scrollIconRef}
              alt="none"
            ></IonImg>
          </div> */}

          {clickCount === 1 && <div style={{ marginBottom: "16px" }}></div>}
          {(clickCount === 1 && (calculateCompletedBodyCount(SkinSelfCheckDataService.bodyMap)) > 0) &&  (
            <HomeSkinSelfCheck
              title={currentMonth + "’s Skin-Self Check"}
              imageUrl="/assets/images/sscm.svg"
              desc={
                "You have completed <strong>" +
                skinPercent +
                "%</strong> of your <strong>" +
                currentMonth +
                "</strong> Skin-Self Check."
              }
              buttonText="Continue to Skin Self Check"
              haveMultipleButtons={false}
              click={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();
                UserService.controlBottomBarFromBack = 2;
                handleDispatchEvent();
                router.push("/home/skinchecktab", "root", "push");
                // setTimeout(() => {

                // }, 500);
              }}
            />
          )}

{(clickCount === 1 && (calculateCompletedBodyCount(SkinSelfCheckDataService.bodyMap)) <= 0) &&  (
            <HomeSkinSelfCheck
              title={currentMonth + "’s Skin-Self Check"}
              imageUrl="/assets/images/sscm.svg"
              desc={
                "You have completed <strong>" +
                skinPercent +
                "%</strong> of your <strong>" +
                currentMonth +
                "</strong> Skin-Self Check."
              }
              buttonText="Let's Start"
              haveMultipleButtons={false}
              click={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();
                UserService.controlBottomBarFromBack = 2;
                handleDispatchEvent();
                router.push("/home/skinchecktab", "root", "push");
                // setTimeout(() => {

                // }, 500);
              }}
            />
          )}
          {clickCount === 1 && <div style={{ marginBottom: "24px" }}></div>}
          {/* {(clickCount === 1) && <div className="">
            <IonGrid className="htDivText">
              <IonRow>
                <IonCol size="11">
                  <IonText className="htHeading">
                    myStuff
                  </IonText>
                </IonCol>
                <IonCol size="1">
                  <IonImg className="htImgStuff" src="/assets/icons/RightOutline-right.svg"></IonImg>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>} */}
          {/* {(clickCount === 1) && <div style={{ marginBottom: "16px" }}></div>} */}
          {/* {(clickCount === 1) && <IonGrid>
            <IonRow>
              <HomeMyStuffRewards
                imageUrl="/assets/icons/Badge.svg"
                title="Badges Earned"
                numerator="12"
                denominator="/30"
              />
              <div style={{ margin: "8px" }}></div>
              <HomeMyStuffRewards
                imageUrl="/assets/icons/Prize.svg"
                title="Prizes Earned"
                numerator="2"
                denominator="/5"
              />
              </IonRow>
          </IonGrid>} */}
          {/* {(clickCount === 1) && <div style={{ marginBottom: "8px" }}></div>}
          {(clickCount === 1) && <IonGrid><IonRow><IonCol size="12"><IonText className="htTextmystuff">How to earn badges & prizes?</IonText></IonCol></IonRow></IonGrid>} */}

          {clickCount === 1 && <div style={{ marginBottom: "16px" }}></div>}
          {clickCount === 1 && (
            <SkinCheckProgress hideDesc={true} progresses={progresses} />
          )}
          {/* {(clickCount === 1) && <div style={{ marginBottom: "16px" }}></div>}
          {(clickCount === 1) && <HomeSSCGoal imageUrl="assets/images/flag1.svg" text="Goals from <strong>Skin Self-Check Core</strong>" count={2} />}
          {(clickCount === 1) && <div style={{ marginBottom: "16px" }}></div>}
          {(clickCount === 1) && <HomeSSCGoal imageUrl="assets/images/flag2.svg" text="Goals from <strong>Sun Safe Behavior Core</strong>" count={2} />} */}

          {clickCount === 1 && <div style={{ marginBottom: "24px" }}></div>}
          {clickCount === 1 && (
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">
                      Continue Your Reading
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
          {clickCount === 1 && <div style={{ marginBottom: "16px" }}></div>}
          {clickCount === 1 && lastCompletedIndexOfCore1 !== 8 && (
            <HomeCoreProgress
              imageUrl="/assets/images/Learning.svg"
              coreNo="Section 1"
              coreTitle="Learn About Spots"
              coresCompleted={lastCompletedIndexOfCore1}
              totalCores={8}
              click={() => {
                navigateToCurrentChapter(1);
              }}
            />
          )}
          {clickCount === 1 && lastCompletedIndexOfCore1 === 8 && (
            <HomeCoreProgress
              imageUrl="/assets/images/Sun Safe Behavior.svg"
              coreNo="Section 2"
              coreTitle="Sun Safe Behaviors"
              coresCompleted={lastCompletedIndexOfCore2}
              totalCores={6}
              click={() => {
                navigateToCurrentChapter(2);
              }}
            />
          )}

          {clickCount === 2 && <div style={{ marginBottom: "16px" }}></div>}
          {(clickCount === 2 && (calculateCompletedBodyCount(SkinSelfCheckDataService.bodyMap)) > 0) && (
            <HomeSkinSelfCheck
              title="Skin-Self Check"
              imageUrl="/assets/images/sscm.svg"
              desc="Keep on tracking your skin & log any sucpicious spots"
              buttonText="Continue to Skin-Self Check"
              haveMultipleButtons={true}
              click={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();

                // router.push("/home/skinchecktab", "root", "replace");
                const el = document.getElementById("tab-button-tab3");
                (el as HTMLElement).click();
              }}
            />
          )}
          {((clickCount === 2) && (calculateCompletedBodyCount(SkinSelfCheckDataService.bodyMap)) <= 0) && (
            <HomeSkinSelfCheck
              title="Skin-Self Check"
              imageUrl="/assets/images/sscm.svg"
              desc="Please start your <strong>first Skin-Self Check.</strong>"
              buttonText="Let's Start"
              haveMultipleButtons={true}
              click={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();
                UserService.controlBottomBarFromBack = 2;
                handleDispatchEvent();
                router.push("/home/skinchecktab", "root", "push");
              }}
            />
          )}

          {clickCount === 2 && <div style={{ marginBottom: "32px" }}></div>}

          {clickCount === 2 && (
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="12">
                    <IonText className="htHeading">Review Cores</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
          {clickCount === 2 && (
            <IonGrid>
              <IonRow>
                <HomeStateCompleted
                  imageUrl="/assets/images/Core1small.svg"
                  title="Section 1"
                  statusImgUrl="/assets/icons/CheckMark.svg"
                  desc="Learn About Spots"
                  isCoreCompleted={true}
                />
                <div style={{ margin: "8px" }}></div>
                <HomeStateCompleted
                  imageUrl="/assets/images/Core2small.svg"
                  title="Section 2"
                  statusImgUrl="/assets/icons/CheckMark.svg"
                  desc="Sun Safe Behaviors"
                  isCoreCompleted={false}
                />
              </IonRow>
            </IonGrid>
          )}

          {(clickCount === 0 || clickCount === 3) && (
            <div style={{ marginBottom: "16px" }}></div>
          )}
          {(clickCount === 0 || clickCount === 3) && (
            <HomeIntro
              imageUrl="assets/images/Welcome.svg"
              title="Welcome to mySmartSkin"
              desc="mySmartSkin helps melanoma survivors learn more about the spots on their body and sun safety.  You will be able to record any suspicious spots to track them and share with your doctor.​"
              question="Do you want to take a tour?"
              buttonText="(Yes, Show Me Around)"
              parentButton={() => {
                moveToTour();
              }}
            />
          )}
          {(clickCount === 0 || clickCount === 3) && (
            <div className="hisDivCores">
              <IonGrid className="hisGridLearn">
                <IonRow>
                  <IonCol size="12">
                    <IonText className="hisTextLearn">
                      Start Your Learning
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
          {(clickCount === 0 || clickCount === 3) && (
            <HomeCores
              coreimageUrl="/assets/images/coreLearn.svg"
              corestatusimageurl="/assets/icons/LockFill.svg"
              coreno="Section 1"
              corelessonname="Learn About Spots"
              corelessondesc=""
              isCoreLocked={false}
            />
          )}
          {(clickCount === 0 || clickCount === 3) && (
            <div style={{ marginBottom: "16px" }}></div>
          )}
          {/* {(clickCount === 0 || clickCount === 3) && (
            <HomeCores
              coreimageUrl="/assets/images/coreLearn2.svg"
              corestatusimageurl="/assets/icons/LockFill.svg"
              coreno="Section 2"
              corelessonname="Sun Safe Behaviors"
              corelessondesc=""
              isCoreLocked={true}
            />
          )} */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomeTab;
