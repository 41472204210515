import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import "./CC1.css";

const CC1: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const navigateBack = () => {
    router.goBack();
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
            <IonText className="titleText">Page 3</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
        <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Skin Self-Checks
          </IonText>

          {/* Main body here */}
          <div style={{ width: "100%", height: "16px" }} />

        <IonImg src="/assets/images/cc/Sun protection.svg"></IonImg>
            <div style={{ width: "100%", height: "16px" }} />

          
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "<strong>50%</strong> of people find their skin cancer on their own.  Checking your body <strong>each month</strong> will allow you to monitor your skin for any new, changing or unusual spots between doctor visits.  The <strong>earlier</strong> skin cancer spots are found, the <strong>simpler</strong> they are to treat.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "The most thorough <strong>skin self-checks</strong> should only take <strong>10-15 minutes</strong> to complete each <strong>month</strong>.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">Before you do a <strong>skin self-check</strong>, gather the right tools:
              <ul style={{ marginLeft: "16px" }}>
                <li>Full-length mirror​</li>
                <li>Handheld mirror​​</li>
                <li>Notebook or phone to document your findings​</li>
                <li>Blow dryer and comb to check your scalp​</li>
                <li>Ruler </li>
              </ul>
            </IonText>
            <div style={{ width: "100%", height: "16px" }} />
            <div className="ccaDescTextContainer">
              <IonText
                className="ccaDescText"
                dangerouslySetInnerHTML={{
                  __html: "Consider asking a family member or friend to assist you with your exam for those <strong>hard to see areas</strong> such as your back and scalp.",
                }}
              ></IonText>
            </div>
            <div style={{ width: "100%", height: "132px" }} />
          </div>
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc2")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC1;
