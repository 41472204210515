import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import "./CC0.css";
import { UserService } from "../../../services/UserService";

const CC0: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const navigateBack = () => {
    router.goBack();
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
            <IonText className="titleText">Page 2</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            UserService.controlBottomBarFromBack=0;
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
        <IonText
              className="ion-margin-bottom default-text cccoContentHeading"

            >
              Risk of Recurrence
            </IonText>
            <div style={{ width: "100%", height: "16px" }} />
        <IonImg src="/assets/images/cc/Welcome 1.svg"></IonImg>
            <div style={{ width: "100%", height: "16px" }} />
            <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "People who have had melanoma have up to <strong>9 times</strong> the risk of having another melanoma compared to people who have never had it.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "As a melanoma survivor, it is important that you do what you can to prevent recurrence.  Checking your body for <strong>signs of skin cancer</strong> and practicing <strong>sun safe behaviors</strong> will allow you to take an active role in your survivorship care.",
              }}
            ></IonText>
          </div>

            {/* <SummaryDescription text="People who have had melanoma have up to <strong>9 times</strong> the risk of having another melanoma compared to people who have never had it." />
            <SummaryDescription text="As a melanoma survivor, it is important that you do what you can to prevent recurrence.  Checking your body for <strong>signs of skin cancer</strong> and practicing <strong>sun safe behaviors</strong> will allow you to take an active role in your survivorship care." /> */}
            <div style={{ width: "100%", height: "120px" }} />
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      router.push("/cc1")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC0;
