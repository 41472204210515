import { DatetimeCustomEvent, IonDatetime, IonText, useIonViewWillEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import "./dateTimePicker.css";
import { format } from "date-fns";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";

interface DateTimePickerProps {
  onselectedDatehandler: Function
}



const DateTimePicker: React.FC<DateTimePickerProps> = ({
  onselectedDatehandler
}) => {
  var value =30*24;
  var currentDate = new Date().getTime();
  var minDate = format(currentDate, "yyyy-MM-dd");
  var futureDate = new Date(currentDate + (1000 * 60 * 60 * value));
  var maxDate = format(futureDate, "yyyy-MM-dd");

  const [selectedtempTime, setSelectedTempTime] = useState(format(new Date(), "hh:mm a"));

  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);


  const handleDateChange = (event: DatetimeCustomEvent) => {
    var incomingDateString: any = event.detail.value;
    // Parse the incoming date string into a Date object
    const dateObject = new Date(incomingDateString);

    // Format the date according to the user's locale or specific format
    SkinSelfCheckDataService.skincheckReminderselectedDate = format(dateObject, "yyyy-MM-dd")
    if (SkinSelfCheckDataService.skincheckReminderselectedTime === "00:00") {
      var time = new Date();
      incomingDateString = SkinSelfCheckDataService.skincheckReminderselectedDate + " " + format(time, "HH:mm:ss") + ".000";
    } else {
      incomingDateString = SkinSelfCheckDataService.skincheckReminderselectedDate
        + " " + SkinSelfCheckDataService.skincheckReminderselectedTime + ".000";
    }
    console.log("picker date", incomingDateString);
    onselectedDatehandler(incomingDateString);
  };

  const handleTimeChange = (event: DatetimeCustomEvent) => {
    var incomingDateString: any = event.detail.value;
    // Parse the incoming date string into a Date object
    const dateObject = new Date(incomingDateString);

    // Format the date according to the user's locale or specific format
    SkinSelfCheckDataService.skincheckReminderselectedTime = format(dateObject, "HH:mm:ss");
    setSelectedTempTime(format(dateObject, "hh:mm a"));
    if (SkinSelfCheckDataService.skincheckReminderselectedDate.length == 0) {
      var date = new Date();
      incomingDateString = format(date, "yyyy-MM-dd") + " " + SkinSelfCheckDataService.skincheckReminderselectedTime + ".000";
    } else {
      incomingDateString = SkinSelfCheckDataService.skincheckReminderselectedDate + " " + SkinSelfCheckDataService.skincheckReminderselectedTime + ".000";
    }
    console.log("picker time", incomingDateString);
    onselectedDatehandler(incomingDateString);
  };

  const openTimePickerHandler = () => {
    setOpenTimePicker(!openTimePicker);
  };

  return (
    <>
      <div className="date-picker-main">
        <IonDatetime
          className="dateTime-picker"
          onIonChange={handleDateChange}
          min={minDate}
          max={maxDate}
          presentation="date"
        ></IonDatetime>
      </div>
      <div className="time-picker-main-div" onClick={openTimePickerHandler}>
        <IonText className="time-title">Time:</IonText>
        <div className="time-picker-div">
          <IonText className="time-picker">{selectedtempTime}</IonText>
        </div>
      </div>
      {openTimePicker && (
        <div className="date-picker-main">
          <IonDatetime
            className="dateTime-picker-time"
            onIonChange={handleTimeChange}
            presentation="time"
            hourCycle={"h12"}
            preferWheel={true}
          ></IonDatetime>
        </div>
      )}
    </>
  );
};
export default DateTimePicker;
